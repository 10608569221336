/**
 * Main application JavaScript file.
 *
 * @author Miša Brežanac <brezanac@gmail.com>
 */

/**
 * It starts...
 */
App = {}

/**
 * Main configuration.
 */
App.Config = {
    // Just a nice round-up of used breakpoints, for reference.
    breakpoints: {
        phone: {
            min: 0,
            max: 767
        },

        tablet: {
            min: 768,
            max: 1199
        },

        desktop: {
            min: 1200,
            max: 2559
        },

        big_desktops: {
            min: 2560,
            max: Infinity
        }
    },
    /**
     * Display of debug information in the console.
     */
    debug: true
}

/**
 * Main entry point for the application.
 */
App.init = function AppInit() {
    App.Utils.debug('[App.init] Initializing the App.');

    // Viewport resize or just changing landscape/portrait mode on devices.
    window.addEventListener('resize', function () {
        App.MainMenu.updateSubmenuOnResize();
    });

    // Functionality that requires a loaded DOM to work.
    App.Utils.ready(function () {
        App.ContactForm.init();
        App.MainMenu.init();

        // Adding smooth scroll functionality to the "Products" button on the homepage.
        var btn = document.querySelector('.homepage-header-products-btn');
        if (btn) {
            btn.addEventListener('click', function () {
                document.getElementById('homepage-products-anchor').scrollIntoView({
                    behavior: 'smooth'
                });
            });
        }
    });
};

/**
 * Main menu related stuff.
 */
App.MainMenu = {
    init: function () {
        App.Utils.debug('[App.MainMenu.init] Initializing main menu.');

        // Menu toggle click event listener.
        document.getElementById('menu-toggle-btn').addEventListener('click', App.MainMenu.togglePhonesMainMenu);

        // If the overlay is not available, we create one.
        if (document.getElementById('main-content-overlay') === null) {
            App.Utils.debug('[App.MainMenu.init] No menu overlay detected. Creating one.');
            var overlay = document.createElement('div'),
                mainContent = document.getElementById('main-content');

            overlay.setAttribute('id', 'main-content-overlay');
            overlay.classList.add('main-content-overlay');
            overlay.addEventListener('click', App.MainMenu.togglePhonesMainMenu);
            mainContent.appendChild(overlay);
        }

        // On tablets clicking the products link should toggle the main menu.
        if (window.innerWidth >= App.Config.breakpoints.tablet.min && window.innerWidth <= App.Config.breakpoints.tablet.max) {
            var productsLink = document.getElementById('products-link');
            productsLink.addEventListener('click', App.MainMenu.displayProductsExitButton);
        }

        // Making sure to highlight the current link in the phones main menu.
        var links = document.querySelectorAll('.main-menu a');
        for (var link in links) {
            if (links.hasOwnProperty(link) && links[link].getAttribute('href') === window.location.pathname) {
                links[link].classList.add('active');
            }
        }
    },

    /**
     * Toggles the display of the off-canvas menu,
     * which is displayed on phones only.
     */
    togglePhonesMainMenu: function () {
        App.Utils.debug('[App.MainMenu.toggle] Toggling the sidebar menu.');

        // Inner off-canvas wrapper that contains all the content.
        var wrapper = document.getElementById('off-canvas-inner-wrapper'),
            toggleBtn = document.getElementById('menu-toggle-btn'),
            overlay = document.getElementById('main-content-overlay');

        switch (wrapper.getAttribute('data-off-canvas-status')) {
            case 'open':
                wrapper.classList.remove('is-open');
                wrapper.setAttribute('data-off-canvas-status', 'closed');
                toggleBtn.classList.remove('is-active');
                overlay.classList.remove('is-active');
                break;
            case 'closed':
                wrapper.classList.add('is-open');
                wrapper.setAttribute('data-off-canvas-status', 'open');
                toggleBtn.classList.add('is-active');
                overlay.classList.add('is-active');
                break;
        }
    },

    /**
     * Displays the the products menu exit button,
     * which is displayed on tablets only.
     */
    displayProductsExitButton: function (e) {
        // Checking again if the current viewport is still a tablet.
        if (window.innerWidth >= App.Config.breakpoints.tablet.min && window.innerWidth <= App.Config.breakpoints.tablet.max) {
            if (document.getElementById('products-menu-exit') === null) {
                // If no exit button exists, one will be created.
                App.Utils.debug('[App.MainMenu.productExitButton] Adding the products menu exit option.');
                var productsMenuExitButton = document.createElement('a');
                productsMenuExitButton.innerText = 'IZLAZ';
                productsMenuExitButton.setAttribute('id', 'products-menu-exit');
                productsMenuExitButton.classList.add('products-menu-exit');
                document.body.appendChild(productsMenuExitButton);

                // We also need a click event on the button to trigger closing.
                document.getElementById('products-menu-exit').addEventListener('click', App.MainMenu.hideProductsExitButton);

                // Body also needs to be a close event target in case the click was not made on the button.
                document.body.addEventListener('click', App.MainMenu.hideProductsExitButton);
            }

            // Making sure to prevent the page from navigating away on clicking 'Products' in the menu.
            e.preventDefault();
            e.stopPropagation();

            // Calculating where the exit button should be displayed.
            var buttonOffsetTop = document.getElementById('products-submenu').offsetTop + document.getElementById('products-submenu').offsetHeight;

            // Actually displaying the exit button.
            var exitButton = document.getElementById('products-menu-exit');
            exitButton.style.top = buttonOffsetTop + 'px';
            exitButton.classList.add('is-visible');
        }
    },

    /**
     * Closes the products submenu on tablets.
     */
    hideProductsExitButton: function () {
        /*
         * Since closing could have been triggered by clicking anywhere we need to explicitly
         * target the closing button.
         */
        document.getElementById('products-menu-exit').classList.remove('is-visible');
    },

    /**
     * Updates the submenu on every resize.
     */
    updateSubmenuOnResize: function () {
        App.Utils.debug('[App.Utils.debug] Window was resized. Updating menus...');
        // In case the products menu is visible it needs to be closed.
        if (document.getElementById('products-menu-exit') !== null) {
            console.log('Aye');
            var exitButton = document.getElementById('products-menu-exit');

            // Fake click event.
            var clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });

            // Triggering the click.
            exitButton.dispatchEvent(clickEvent);
        }
    }
}

/**
 * Product gallery
 */
App.Gallery = {
    /**
     * Products gallery instance.
     */
    gallery: {},

    /**
     * Gallery options.
     */
    options: {},

    init: function () {
        App.Utils.debug('[App.Gallery.init] Initializing the product gallery.');
        this.gallery = GLightbox(App.Gallery.options);
    }
}

/**
 * Handy utility functions.
 */
App.Utils = {
    /**
     * A stand-in replacement for jquery ready() to trigger on DOMContentLoaded.
     */
    ready: function (callback) {
        if (document.readyState !== 'loading') {
            callback();
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }
    },

    /**
     * Spits out messages if debug mode is enabled.
     */
    debug: function (message) {
        if (App.Config.debug) {
            console.log(message);
        }
    }
}

/**
 * Mini contact form (default layout) related functionality.
 */
App.ContactForm = {
    init: function () {
        App.Utils.debug('[App.ContactForm] Initializing contact form.');

        document.getElementById('contact-form').addEventListener('submit', function (e) {
            e.preventDefault();

            // Validating contact form data.
            if (App.ContactForm.validate()) {
                // All is well John Spartan...
                App.ContactForm.send();
            }
        })

        // Adding a simple close event handler to the overlay exit button.
        document.querySelector('.contact-form-overlay-close-btn').addEventListener('click', function () {
            App.ContactForm.toggleOverlay();
        });
    },

    /**
     * Validated contact form data.
     */
    validate: function () {
        App.Utils.debug('[App.ContactForm] Validating the form...');

        /**
         * The initial state of the validation process.
         */
        var isValid = true;

        /**
         * The list of form elements with data to validate.
         */
        var formData = {
            name: document.getElementById('contact-form-name'),
            email: document.getElementById('contact-form-email'),
            phone: document.getElementById('contact-form-phone'),
            message: document.getElementById('contact-form-message')
        }

        // Resetting the error notifications first.
        var labels = document.querySelectorAll('label');
        for (var label in labels) {
            if (labels.hasOwnProperty(label)) {
                labels[label].classList.remove('invalid');
            }
        }

        // Validating the form data.
        for (var field in formData) {
            // Checking for general validity first.
            if (!formData[field].validity.valid) {
                var errorContainer = formData[field].parentNode.querySelector('.contact-form-error'),
                    parentNode = formData[field].parentNode,
                    errorMessage = '';

                // Making sure to indicate that the form is right away not valid.
                isValid = false;

                /**
                 * Here we test for only two scenarios:
                 * - valueMissing - field is marked as required but it lacks an actual value
                 * - typeMismatch - entered data doesn't conform with the type value (i.e. email etc.)
                 * - tooShort - entered data is shorter than specified by the minlength attribute
                 * - tooLong - entered data is longer than specified by the maxlength attribute
                 */
                if (formData[field].validity.valueMissing) {
                    // Missing value for name.
                    errorMessage = errorContainer.getAttribute('data-error-value-missing');
                } else if (formData[field].validity.tooShort) {
                    // The provided value is too short (should actually never happen).
                    errorMessage = errorContainer.getAttribute('data-error-too-short');
                } else if (formData[field].validity.tooLong) {
                    // The provided value is too long (should actually never happen).
                    errorMessage = errorContainer.getAttribute('data-error-too-long');
                } else if (formData[field].validity.typeMismatch) {
                    // Type mismatch. Also checked as a secondary requirement.
                    errorMessage = errorContainer.getAttribute('data-error-type-mismatch');
                }

                // Creating the actual message element if it doesn't exist yet.
                if (!errorContainer.hasChildNodes()) {
                    errorContainer.appendChild(document.createElement('span'));
                }

                // Styling the message.
                errorContainer.querySelector('span').textContent = errorMessage;
                parentNode.classList.add('invalid');
            }
        }

        return isValid;
    },

    /**
     * Send the contact form.
     */
    send: function () {
        App.Utils.debug('[App.ContactForm] Sending the form...');

        // Displaying the overlay right away!
        App.ContactForm.toggleOverlay();

        // The loader too!
        App.ContactForm.toggleLoader();

        // Then reset the status messages.
        App.ContactForm.resetStatus();

        /**
         * Form data to be sent.
         */
        var formData = {
            name: document.getElementById('contact-form-name'),
            email: document.getElementById('contact-form-email'),
            phone: document.getElementById('contact-form-phone'),
            message: document.getElementById('contact-form-message')
        }

        /**
         * Request query key pairs.
         */
        var queryKeyPairs = [];

        for (var key in formData) {
            queryKeyPairs.push(key + '=' + encodeURIComponent(formData[key].value));
        }

        /**
         * The actual query string to be sent.
         * The '+' replacement makes sure to replace all spaces with +, to make it browser compatible.
         */
        var queryString = queryKeyPairs.join('&').replace(/%20/g, '+');

        var request = new XMLHttpRequest();

        // Handling received responses.
        request.addEventListener('load', function () {
            if (request.status === 200) {
                var responseData = JSON.parse(request.responseText);

                if (responseData.status && !responseData.errors.length) {
                    // The contact form was successfully sent.
                    App.ContactForm.updateStatus(true);
                } else {
                    // The request did come through but server reported errors (possibly server side validation failed).
                    App.ContactForm.updateStatus(false);
                }
            }
        });

        // Handling server connection errors.
        request.addEventListener('error', function () {
            // There was an error with establishing a connection to the server.
            App.ContactForm.updateStatus(false);

            if (App.Config.debug) {
                App.Utils.debug('[App.ContactForm] There was an error while trying to send form data.');
            }
        });

        // Sending the actual request.
        request.open('POST', 'contact-form/submit');
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        // Setting a custom header which will make the server only accept contact form requests from here.
        request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'xmlhttprequest');
        request.send(queryString + '&timestamp=' + (new Date().getTime()));
    },

    /**
     * Updates the status of the contact form submission response.
     */
    updateStatus: function (success) {
        /**
         * Contact form overlay.
         */
        var contactFormOverlay = document.querySelector('.contact-form-overlay');

        /**
         * The contact form status message container.
         */
        var statusContainer = document.querySelector('.contact-form-status');

        // Received status means the loader needs to be toggled off if it's visible.
        if (contactFormOverlay.classList.contains('active')) {
            App.ContactForm.toggleLoader();
        }

        // Removing any child nodes inside the containers if they are present.
        if (statusContainer.hasChildNodes()) {
            while (statusContainer.firstChild) {
                statusContainer.removeChild(statusContainer.firstChild);
            }
        }

        // Displaying the exit button.
        App.ContactForm.toggleExitButton();

        /**
         * Elements for status and repeat message.
         */
        var statusMessage = document.createElement('span'),
            statusRepeat = document.createElement('a');

        // Status and repeat messages for both success and failure.
        statusMessage.textContent = (success) ? statusContainer.getAttribute('data-status-success') : statusMessage.textContent = statusContainer.getAttribute('data-status-failure');
        statusRepeat.textContent = (success) ? statusContainer.getAttribute('data-status-success-repeat') : statusContainer.getAttribute('data-status-failure-repeat');

        // Status repeat message needs to additionally get an event listener for the repeat action.
        statusRepeat.addEventListener('click', App.ContactForm.resetForm);

        // Appending the newly created elements to the status container.
        statusContainer.appendChild(statusMessage);
        statusContainer.appendChild(statusRepeat);
    },

    /**
     * Toggles the contact form overlay on and off.
     */
    toggleOverlay: function () {
        /**
         * Contact form overlay.
         */
        var contactFormOverlay = document.querySelector('.contact-form-overlay');

        if (contactFormOverlay.classList.contains('active')) {
            contactFormOverlay.classList.remove('active');
        } else {
            contactFormOverlay.classList.add('active');

        }
    },

    /**
     * Toggles the contact form loader on and off.
     */
    toggleLoader: function () {
        /**
         * Contact form loader.
         */
        var contactFormLoader = document.querySelector('.contact-form-loader');

        if (contactFormLoader.classList.contains('active')) {
            contactFormLoader.classList.remove('active');
        } else {
            contactFormLoader.classList.add('active');
        }
    },

    /**
     * Toggles the contact form exit button on and off.
     */
    toggleExitButton: function () {
        /**
         * Contact form loader.
         */
        var contactFormExitButton = document.querySelector('.contact-form-overlay-close-btn');

        if (contactFormExitButton.classList.contains('active')) {
            contactFormExitButton.classList.remove('active');
        } else {
            contactFormExitButton.classList.add('active');
        }
    },

    /**
     * Resets the entire form.
     */
    resetForm: function () {
        /**
         * Reference to the contact form.
         */
        var contactForm = document.getElementById('contact-form');
        contactForm.reset();

        // Additionally remove the contact-form overlay if it's visible.
        var contactFormOverlay = document.querySelector('.contact-form-overlay');
        if (contactFormOverlay.classList.contains('active')) {
            App.ContactForm.toggleOverlay();
        }
    },

    /**
     * Resets the status messages.
     */
    resetStatus: function () {
        /**
         * The contact form status message container.
         */
        var statusContainer = document.querySelector('.contact-form-status');

        // Removing any child nodes inside the containers if they are present.
        if (statusContainer.hasChildNodes()) {
            while (statusContainer.firstChild) {
                statusContainer.removeChild(statusContainer.firstChild);
            }
        }

        // Hide the exit button.
        if (document.querySelector('.contact-form-overlay-close-btn').classList.contains('active')) {
            App.ContactForm.toggleExitButton();
        }
    }
}

/**
 * Buckle your seat belt Dorothy, 'cause Kansas is going bye-bye!
 */
App.init();

